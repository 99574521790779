/* ========================================================= * Material Kit 2 PRO React - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/material-kit-pro-react * Copyright 2021 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

import { useMaterialUIController } from "/context";
import dynamic from "next/dynamic";
import Link from "next/link";
import { robotoSlab } from "/utils/fonts";

const Container = dynamic(() => import("@mui/material/Container"), { loading: () => null });
const Grid = dynamic(() => import("@mui/material/Grid"), { loading: () => null });
const NewspaperRounded = dynamic(() => import("@mui/icons-material/NewspaperRounded"), { loading: () => null });
const MDBox = dynamic(() => import('/components/MDBox'), { loading: () => null });
const MDButton = dynamic(() => import('/components/MDButton'), { loading: () => null });
const MDTypography = dynamic(() => import('/components/MDTypography'), { loading: () => null });

// Parts
const ALLDCard = dynamic(() => import('/examples/ALLDCard'), { loading: () => null });

function OurArticles({ articleCards }) {
  const [controller] = useMaterialUIController();

  return (
    <>
      <MDBox component="section" py={7}>
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={6}
            flexDirection="column"
            justifyContent="center"
            mx="auto"
            sx={{ textAlign: "center" }}
          >
            <MDTypography variant="h2" color={controller.sidenavColor} textGradient className={robotoSlab.className}>
              Our Articles
            </MDTypography>
            <MDTypography variant="h2" mb={2} className={robotoSlab.className}>
              75+ Articles to Read Online
            </MDTypography>
            <MDTypography variant="body1" color="text" mb={2}>
              Delivering insights into property care and renovation for 30+ years.
            </MDTypography>
          </Grid>
          <br />
          <Grid container spacing={6}>
            {articleCards.map((row, index) => (
              <ALLDCard key={`our-articles-${index}`} row={row} pathPrefix="/articles" type="Article" />
            ))}
          </Grid>
          <br />
          <MDBox textAlign="center">
            <Link href="/articles">
              <MDButton
                variant="contained"
                color="dark"
                startIcon={<NewspaperRounded />}
              >More Articles</MDButton>
            </Link>
          </MDBox>
        </Container>
      </MDBox>
    </>
  );
}

export default OurArticles;
